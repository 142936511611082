import React, { useState, useEffect, useCallback } from 'react';
import Papa from 'papaparse';
import ReactApexChart from 'react-apexcharts';
import { format, startOfMonth, endOfMonth, eachDayOfInterval } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaSolarPanel } from "react-icons/fa";
import { MdElectricBolt  } from "react-icons/md";
import { GiRadiations } from "react-icons/gi";

const Chart = ({lastDataInfo }) => {
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [period, setPeriod] = useState(1); // Unified period for all charts
    const [selectedMonth, setSelectedMonth] = useState(new Date()); // New state for month picker
    const [status, setStatus] = useState('inaktív'); // State for active status
    const [lastTimestamp, setLastTimestamp] = useState(''); // State for the last timestamp


    // Helper function to parse numbers with different digit separators
    const parseNumber = (value) => {
        if (typeof value === 'string') {
            // Replace commas with periods for decimal conversion
            return parseFloat(value.replace(',', '.'));
        }
        return parseFloat(value);
    };

    // Function to determine the active status based on the timestamp of the last data point
    const activeStatus = useCallback(() => {
        if (data.length === 0) {
            setStatus('inaktív');
            setLastTimestamp('');
            return;
        }
        const lastDate = new Date(data[data.length - 2].Date);
        const now = new Date();
        const diff = (now - lastDate) / (1000 * 60); // Difference in minutes

        if (diff > 5) {
            setStatus('inaktív');
        } else {
            setStatus('aktív');
        }
        setLastTimestamp(format(lastDate, 'HH:mm'));
    }, [data]);

    // Filter data by selected month and calculate daily totals
    const getMonthlyData = () => {
        const start = startOfMonth(selectedMonth);
        const end = endOfMonth(selectedMonth);
        const daysInMonth = eachDayOfInterval({ start, end });

        // Prepare data for each day
        const dailyTotals = daysInMonth.map(day => {
            // Filter data for the specific day
            const dayData = data.filter(row => {
                const rowDate = new Date(row.Date);
                return rowDate.getDate() === day.getDate() && rowDate.getMonth() === day.getMonth();
            });

            // Calculate daily sum using the same logic as calculateDailyTotalsFromOriginalData
            let dailySum = 0;
            dayData.forEach(row => {
                dailySum += (parseNumber(row.PowerP) + parseNumber(row.currentPower)) / 60;
            });

            return [day, (dailySum / 1000).toFixed(0)];
        });

        // Transform data for charting
        const seriesData = dailyTotals.map(([date, total]) => {
            return { x: format(date, 'yyyy-MM-dd'), y: total };
        });

        return {
            series: [{
                name: 'Napi Termelés MWh',
                data: seriesData
            }]
        };
    };

    // Chart options for the monthly production chart
    const monthlyChartOptions = {
        chart: {
            type: 'bar',
            height: 350
        },
        colors: ['#1B1F4C', '#fccb06'],
        xaxis: {
            type: 'datetime', // Keep datetime type for consistency
        },
        tooltip: {
            x: {
                format: 'dd-MMM-yyyy'
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 2
            }
        },
        responsive: [
            {
                breakpoint: 1000,
                options: {
                    chart: {
                        height: 750, // Double the height for mobile devices
                        type: 'bar',
                        stacked: false
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true
                        }
                    },
                    xaxis: {
                        type: 'numeric', // Keep as numeric to show numbers
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            formatter: function (value) {
                                return format(new Date(value), 'dd-MMM');
                            }
                        }
                    },
                }
            }
        ]
    };


    // Function to calculate the monthly sum of production
    const calculateMonthlySum = () => {
        const start = startOfMonth(selectedMonth);
        const end = endOfMonth(selectedMonth);

        const monthlyData = data.filter(row => {
            const rowDate = new Date(row.Date);
            return rowDate >= start && rowDate <= end;
        });

        let monthlySum = 0;
        monthlyData.forEach(row => {
            monthlySum += (parseNumber(row.PowerP) + parseNumber(row.currentPower)) / 60;
        });

        return monthlySum; // Return the monthly sum
    };

    const monthlySum = calculateMonthlySum();

    // Load and parse CSV data
    useEffect(() => {
        const fetchData = () => {
            Papa.parse(`${process.env.PUBLIC_URL}/data.csv`, {
                download: true,
                header: true,
                dynamicTyping: true,
                complete: function (results) {
                    const parsedData = results.data.map(row => ({
                        ...row,
                        // Check if row.Date exists and is not undefined before calling replace
                        Date: row.Date ? new Date(row.Date.replace(/\./g, '/')) : null,
                        PowerP: parseNumber(row.PowerP),
                        Irrad: parseNumber(row.Irrad),
                        currentPower: parseNumber(row.currentPower),
                        totalRadiation: parseNumber(row.totalRadiation)
                    }));

                    setData(parsedData);
                }
            });
        };

        fetchData(); // Initial fetch

        const interval = setInterval(() => {
            fetchData(); // Fetch every minute
        }, 60000); // 60000 milliseconds = 1 minute

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []); // Empty dependency array means this effect runs only on mount and unmount

    // Filter data by selected date
    const filterDataByDate = useCallback((date) => {
        return data.filter(row => {
            const dataDate = new Date(row.Date);
            return dataDate.toDateString() === date.toDateString();
        });
    }, [data]);

    const compressData = (dataToCompress, minutes) => {
        if (minutes === 1) return dataToCompress; // No compression needed for 1 min

        // Ensure all data is numeric and valid
        const numericData = dataToCompress.map(row => ({
            ...row,
            PowerP: parseNumber(row.PowerP),
            Irrad: parseNumber(row.Irrad),
            currentPower: parseNumber(row.currentPower),
            totalRadiation: parseNumber(row.totalRadiation),
            // Round each date to the nearest interval
            RoundedDate: roundToNearestMinutes(row.Date, minutes),
        }));

        // Group data by RoundedDate
        const groupedByRoundedDate = numericData.reduce((acc, row) => {
            const key = row.RoundedDate.getTime(); // Use the time in milliseconds as the key
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(row);
            return acc;
        }, {});

        // Calculate averages for each group
        const compressed = Object.keys(groupedByRoundedDate).map(time => {
            const group = groupedByRoundedDate[time];
            const average = field => group.reduce((acc, cur) => acc + (cur[field] || 0), 0) / group.length;

            return {
                Date: new Date(parseInt(time)), // Convert back to Date object
                PowerP: average('PowerP'),
                Irrad: average('Irrad'),
                currentPower: average('currentPower'),
                totalRadiation: average('totalRadiation'),
            };
        });

        return compressed;
    };

    // Helper function to round date to the nearest interval
    function roundToNearestMinutes(date, minutes) {
        const ms = 1000 * 60 * minutes; // Convert minutes to milliseconds
        return new Date(Math.round(date.getTime() / ms) * ms);
    }

    // Generate chart data
    const getChartData = (field1, field2, sumFields = false) => {
        const filteredData = filterDataByDate(startDate);
        const compressedData = compressData(filteredData, period);

        let series;
        if (sumFields) {
            series = [{
                name: `Sum of ${field1} and ${field2}`,
                data: compressedData.map(row => [row.Date.getTime(), row[field1] + row[field2]])
            }];
        } else {
            series = [{
                name: field1,
                data: compressedData.map(row => [row.Date.getTime(), row[field1]]),
                // Assign yAxis to 1 if it's Irrad or totalRadiation to put it on the opposite side
                yAxis: field1 === 'Irrad' || field1 === 'totalRadiation' ? 1 : 0
            }, {
                name: field2,
                data: compressedData.map(row => [row.Date.getTime(), row[field2]]),
                yAxis: field2 === 'Irrad' || field2 === 'totalRadiation' ? 1 : 0
            }];
        }

        return {
            series,
            options: {
                chart: {
                    type: 'line',
                    background: 'transparent',
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: false,
                            reset: true
                        },
                        autoSelected: 'zoom'
                    },
                },
                colors: ['#1B1F4C', '#fccb06'],
                markers: {
                    size: 0,
                    colors: ['#1B1F4C', '#fccb06'],
                    strokeColors: '#ffffff',
                    strokeWidth: 0,
                },
                xaxis: {
                    type: 'datetime',
                    tickAmount: 25,
                    labels: {
                        formatter: function (value) {
                            return format(new Date(value), 'HH:mm');
                        },
                    },
                },
                tooltip: {
                    x: {
                        format: 'HH:mm'
                    },
                    y: {
                        formatter: function (value) {
                            return `${value.toFixed(2)}`;
                        }
                    },

                },
                yaxis: [{
                    labels: {
                        formatter: function (val) {
                            return val.toFixed(0); // Format Y-axis labels as integers
                        }
                    },
                    title: {
                        text: field1,
                    },
                    min: 0

                }, {
                    opposite: true, // Position this Y-axis on the opposite side
                    labels: {
                        formatter: function (val) {
                            return val.toFixed(0); // Ensure labels are integers
                        }
                    },
                    title: {
                        text: field2,
                    },

                    show: series.length > 1 // Only show if there's more than one series
                }],
                responsive: [
                    {
                        breakpoint: 1000,
                        options: {
                            xaxis: {
                                tickAmount: 5,
                            },
                        }
                    }
                ]
            }
        };
    };

    const getSumChartData = () => {
        const filteredData = filterDataByDate(startDate);
        const compressedData = compressData(filteredData, period);

        const series = [{
            name: 'Összesített teljesítmény [kW]',
            data: compressedData.map(row => {
                // Ensure both values are valid numbers; if not, treat as 0
                const powerP = parseNumber(row.PowerP) || 0;
                const currentPower = parseNumber(row.currentPower) || 0;
                const sum = powerP + currentPower;

                return [row.Date.getTime(), sum];
            })
        }];

        return {
            series,
            options: {
                chart: {
                    type: 'line',
                    background: 'transparent',
                    animations: {
                        enabled: 'true',
                        easing: 'easein',
                    },

                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: false,
                            reset: true
                        },
                        autoSelected: 'zoom',
                        export: {
                            csv: {
                                filename: "export.csv",
                                columnDelimiter: ';',
                                headerCategory: 'category',
                                headerValue: 'value',
                                dateFormatter(timestamp) {
                                    return format(new Date(timestamp), 'yyyy/MM/dd HH:mm');
                                }
                            },
                        }
                    },

                },
                colors: ['#1B1F4C', '#fccb06'],
                markers: {
                    size: 0,
                    colors: ['#1B1F4C', '#fccb06'],
                    strokeColors: '#ffffff',
                    strokeWidth: 0,
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        formatter: function (value) {
                            return format(new Date(value), 'HH:mm');
                        },
                    },
                    tickAmount: 25,
                },
                tooltip: {
                    x: {
                        format: 'HH:mm'
                    },
                    y: {
                        formatter: function (value) {
                            return `${value.toFixed(2)}`;
                        }
                    },

                },
                yaxis: [
                    {
                        title: {
                            text: 'Teljesítmény (kW)',
                        },
                        labels: {
                            formatter: function (value) {
                                return Number(value).toFixed(0);
                            }
                        },
                    },

                ],
                responsive: [
                    {
                        breakpoint: 1000,
                        options: {
                            xaxis: {
                                tickAmount: 5,
                            },
                        },

                    }
                ]
            }
        };
    };

    const [dailySumTotal, setDailySumTotal] = useState(0);
    const [dailyPowerPTotal, setDailyPowerPTotal] = useState(0);
    const [dailyCurrentPowerTotal, setDailyCurrentPowerTotal] = useState(0);

    // Memoized function to calculate daily totals from original data
    const calculateDailyTotalsFromOriginalData = useCallback(() => {
        // Use the original, uncompressed data filtered for the selected date
        const filteredData = filterDataByDate(startDate);

        // Daily Sum: (PowerP + CurrentPower) / 60 for each data point, summed up for the day
        let dailySum = 0;
        // Daily totals for PowerP and CurrentPower individually, divided by 60 and summed up for the day
        let dailyPowerP = 0;
        let dailyCurrentPower = 0;

        filteredData.forEach(row => {
            dailySum += (parseNumber(row.PowerP) + parseNumber(row.currentPower)) / 60;
            dailyPowerP += parseNumber(row.PowerP) / 60;
            dailyCurrentPower += parseNumber(row.currentPower) / 60;
        });

        // Update state with the calculated totals
        setDailySumTotal(dailySum);
        setDailyPowerPTotal(dailyPowerP);
        setDailyCurrentPowerTotal(dailyCurrentPower);
    }, [filterDataByDate, startDate]);

    useEffect(() => {
        calculateDailyTotalsFromOriginalData();
        activeStatus(); // Check active status whenever data changes
    }, [data, startDate, calculateDailyTotalsFromOriginalData, activeStatus]); // Depend on the original data, selected date, and memoized function


    const getLastValuesForCharts = () => {
        const lastEntry = data[data.length - 2] || {}; // Get the last entry or an empty object if data is empty
        const lastPowerP = parseNumber(lastEntry.PowerP) || 0;
        const lastCurrentPower = parseNumber(lastEntry.currentPower) || 0;
        const sumLastPowerPAndCurrentPower = lastPowerP + lastCurrentPower;

        return {
            lastPowerP: lastPowerP.toFixed(0),
            lastCurrentPower: lastCurrentPower.toFixed(0),
            sumLastPowerPAndCurrentPower: sumLastPowerPAndCurrentPower
        };
    };

    // Use these values to display in the component
    const { lastPowerP, lastCurrentPower, sumLastPowerPAndCurrentPower } = getLastValuesForCharts();

    const getLastValuesForIrradAndRadiation = () => {
        const lastEntry = data[data.length - 2] || {}; // Assuming data is sorted by date
        const lastIrrad = parseNumber(lastEntry.Irrad) || 0;
        const lastTotalRadiation = parseNumber(lastEntry.totalRadiation) || 0;
        const sumLastIrradAndTotalRadiation = lastIrrad + lastTotalRadiation;

        return {
            lastIrrad: lastIrrad.toFixed(0),
            lastTotalRadiation: lastTotalRadiation.toFixed(0),
            sumLastIrradAndTotalRadiation: sumLastIrradAndTotalRadiation.toFixed(0)
        };
    };

    const { lastIrrad, lastTotalRadiation, sumLastIrradAndTotalRadiation } = getLastValuesForIrradAndRadiation();

    return (
        <div className='main'>
            <div className='main-status-cont'>
            <h3 className='main-status-h3'> 
                    Portfólió adatok <span className='phonedisp mobile-status'>|</span> <span style={{fontSize:".7em"}}>Kapcsolat:</span> <span className={status} style={{fontSize: ".65em"}}>{status}</span><span style={{fontSize: ".5em"}}><span className='phonedisp2'><br /></span> Utolsó adat: {lastTimestamp}</span>
                    
                </h3>
                <div className="box-container" >
                    <div className='box'>
                        <div className="box-icon collor">
                            <FaSolarPanel />
                        </div>
                        <h4>Napi termelés:</h4>
                        <p className='box-p'><b>{(dailySumTotal / 1000).toFixed(0)} MWh</b></p>
                    </div>
                    <div className='box' id="box">
                        <div className="box-icon collor">
                            <MdElectricBolt />
                        </div>
                        <h4>Aktuális termelés:</h4>
                        <p className='box-p'><b>{sumLastPowerPAndCurrentPower.toFixed(0)} kW</b></p>
                    </div>
                    <div className='box' id="box">
                        <div className="box-icon collor">
                            <GiRadiations />
                        </div>
                        <h4>Aktuális besug:</h4>
                        <p className='box-p'><b>{sumLastIrradAndTotalRadiation}W/m</b><sup>2</sup></p>
                    </div>
                    <div className='box' id="box">
                        <div className="box-icon collor">
                            <FaSolarPanel />
                        </div>
                        <h4>Havi termelés:</h4>
                        <p className='box-p'><b>{(monthlySum / 1000).toFixed(0)} MWh</b></p>
                    </div>
                </div>
            </div>
            <div className='chartt'  id="portfoliodata">
                <div className="chart1">
                    <h2 className='charth2'>Halmajugra + Bükkábrány</h2>
                        <div className='datepicker-cont-div'>
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}  className='box2' />
                        </div>
                    <div className='filter-container'>
                        <button className='filter-btn' onClick={() => setPeriod(1)}>1 Min</button>
                        <button className='filter-btn' onClick={() => setPeriod(5)}>5 Min</button>
                        <button className='filter-btn' onClick={() => setPeriod(15)}>15 Min</button>
                    </div>

                    <ReactApexChart options={getSumChartData().options} series={getSumChartData().series} type="line" height={450} />
                </div>
                <div className='chart-flex'>
                    <div className="flex1">
                        <h2 className='charth2'>Bükkábrány</h2>
                        <ReactApexChart options={getChartData('PowerP', 'Irrad').options} series={getChartData('PowerP', 'Irrad').series} type="line" height={400} />
                        <div className="box-container-chart2">
                            <div className='box3'>
                                <div className="box-icon collor2">
                                    <FaSolarPanel />
                                </div>
                                <p className='box-p'>{(dailyPowerPTotal / 1000).toFixed(0)} MWh</p>
                            </div>
                            <div className='box3'>
                                <div className="box-icon collor2">
                                    <MdElectricBolt />
                                </div>
                                <p className='box-p'>{lastPowerP} kW</p>
                            </div >                           
                            <div className='box3'>
                                <div className="box-icon collor2">
                                    <GiRadiations />
                                </div>
                                <p className='box-p '>{lastIrrad} W/m<sup>2</sup></p>
                            </div>
                        </div>
                    </div>
                    <div className="flex2">
                        <h2 className='charth2'>Halmajugra</h2>
                        <ReactApexChart options={getChartData('currentPower', 'totalRadiation').options} series={getChartData('currentPower', 'totalRadiation').series} type="line" height={400} />
                        <div className="box-container-chart2">
                            <div className='box3'>
                                <div className="box-icon collor2">
                                    <FaSolarPanel />
                                </div>

                                <p className='box-p'>{(dailyCurrentPowerTotal / 1000).toFixed(0)} MWh</p>
                            </div>
                            <div className='box3'>
                                <div className="box-icon collor2">
                                    <MdElectricBolt />
                                </div>

                                <p className='box-p'>{lastCurrentPower} kW</p>
                            </div>
                            <div className='box3'>
                                <div className="box-icon collor2">
                                    <GiRadiations />
                                </div>

                                <p className='box-p'>{lastTotalRadiation} W/m<sup>2</sup></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ boxShadow: "0 7px 29px 0 #64646f33" }} id="monthlyprod">

                    <div className="datepicker-cont">
                        <div className="monthly-sum-display">
                            <h2 className='charth2'><br />Havi termelés</h2>
                            <p className='charth2'>{(monthlySum / 1000).toFixed(0)} MWh</p> {/* Assuming the sum is in kWh, convert to MWh */}
                        </div>
                        <DatePicker
                            selected={selectedMonth}
                            onChange={(date) => setSelectedMonth(date)}
                            showMonthYearPicker
                            dateFormat="MM/yyyy"
                            className='box2'
                        />
                    </div>
                    <ReactApexChart options={monthlyChartOptions} series={getMonthlyData().series} type="bar" height={400} />
                </div>
            </div>
        </div>
    );
};

export default Chart;

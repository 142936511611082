import React, { useState, useEffect } from 'react';
import TodayDate from './Todaydate';
import { FaSolarPanel, FaBars, FaTimes, FaHome, FaCloudSun, FaChartLine, FaChartBar } from 'react-icons/fa';
import { IoLogOut } from 'react-icons/io5';

const Navbar = ({ onLogout }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isNavbarReduced, setIsNavbarReduced] = useState(false);

  const handleMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsNavbarReduced(true);
    } else {
      setIsNavbarReduced(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <nav className={`navbar-main ${isNavbarReduced ? 'navbar-reduced' : ''}`} id="home">
        <div className='navbar-text'>
          <p className='flex navbar-logoname'><FaSolarPanel />{"   "}<b>EnergyForce PV monitor</b></p>
        </div>
        <div className={`navbar-menu ${isMobileMenuOpen ? 'open' : ''}`}>
          <ul>
            <li><a onClick={scrollToTop} href="#home" className='menuitem'>Főoldal</a></li>
            <li><a href="#termeles" className='menuitem'>Termelés</a></li>
            <li><a href="#entsoe" className='menuitem'>ENTSO-e</a></li>
            <li><a href="#terkep" className='menuitem'>Térkép</a></li>
            <li><a className='menuitem logoutbtn' href='#' onClick={onLogout}>Kilépés</a></li>
          </ul>
        </div>
        <div className="navbar-text todaydatediv ">
          <TodayDate />
        </div>
        <div className="navbar-menu-icon" onClick={handleMenuToggle}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </nav>
      <nav className="navbar-mobile">
        <div className="navbar-mobile-header">
          <div className="navbar-mobile-item"><a onClick={scrollToTop} href="#home" className='menuitem'><FaHome /></a></div>
          <div className="navbar-mobile-item"><a href="#termeles" className='menuitem'><FaSolarPanel /></a></div>
          <div className="navbar-mobile-item"><a href="#portfoliodata" className='menuitem'><FaChartLine /></a></div>
          <div className="navbar-mobile-item"><a href="#monthlyprod" className='menuitem'><FaChartBar /></a></div>
          <div className="navbar-mobile-item"><a href="#terkep" className='menuitem'><FaCloudSun /></a></div>
          <div className="navbar-mobile-item"><a className='menuitem logoutbtn' href='#' onClick={onLogout}><IoLogOut /></a></div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import { format } from 'date-fns';
import Papa from 'papaparse';

const Entsoe = () => {
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [lastDataInfo, setLastDataInfo] = useState({ time: '', price: 0 });

  useEffect(() => {
    const fetchXMLData = async () => {
      try {
        const response = await axios.get('/activationprice.xml', {
          'Content-Type': 'application/xml; charset=utf-8'
        });
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response.data, 'text/xml');
        const timeSeriesList = xmlDoc.getElementsByTagName('TimeSeries');
        const points = [];

        for (let i = 0; i < timeSeriesList.length; i++) {
          const timeSeries = timeSeriesList[i];
          const periods = timeSeries.getElementsByTagName('Period');

          for (let j = 0; j < periods.length; j++) {
            const period = periods[j];
            const pointsData = period.getElementsByTagName('Point');

            for (let k = 0; k < pointsData.length; k++) {
              const point = pointsData[k];
              const position = parseInt(point.getElementsByTagName('position')[0].textContent, 10);
              const price = Math.round(parseFloat(point.getElementsByTagName('activation_Price.amount')[0].textContent))/1000;
              const category = point.getElementsByTagName('imbalance_Price.category')[0].textContent;

              if (position <= 96) {
                points[position - 1] = {
                  position,
                  price,
                  category,
                };
              }
            }
          }
        }

        // Fill missing positions with default values
        for (let i = 0; i < 96; i++) {
          if (!points[i]) {
            points[i] = { position: i + 1, price: 0, category: 'N/A' };
          }
        }

        return points;
      } catch (error) {
        console.error('Error loading XML file:', error);
        return [];
      }
    };

    const fetchCSVData = async () => {
      try {
        const response = await axios.get('/hupx.csv');
        const parsedData = Papa.parse(response.data, {
          header: true,
          skipEmptyLines: true,
        });

        const csvData = parsedData.data;
        const points = new Array(96).fill(0);

        csvData.forEach(row => {
          const negyedOra = parseInt(row['Negyedóra'], 10);
          const hupxHuf = parseFloat(row['Hupxhuf']);
          if (negyedOra <= 96) {
            points[negyedOra - 1] = hupxHuf;
          }
        });

        return points;
      } catch (error) {
        console.error('Error loading CSV file:', error);
        return [];
      }
    };

    const fetchData = async () => {
      const xmlPoints = await fetchXMLData();
      const csvPoints = await fetchCSVData();

      const categories = Array.from({ length: 96 }, (_, i) => {
        const hours = Math.floor(i / 4);
        const minutes = (i % 4) * 15;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
      });

      setChartOptions({
        chart: {
          type: 'line',
          height: 350,
        },
        stroke: {
          curve: 'smooth',
        },
        colors: ['#1B1F4C', '#fccb06'],
        xaxis: {
          categories,
          tickAmount: 40,
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: false,
          },
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              xaxis: {
                tickAmount: 5,
              },
            },
          },
        ],
      });

      setChartSeries([
        {
          name: 'KE ár HUF/kWh',
          data: xmlPoints.map(point => point.price),
        },
        {
          name: 'HUPX ár HUF/kWh',
          data: csvPoints,
        },
      ]);

      // Extract the last data time from the XML and format it
      const xmlResponse = await axios.get('/activationprice.xml', {
        'Content-Type': 'application/xml; charset=utf-8'
      });
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlResponse.data, 'text/xml');
      const dateTime = xmlDoc.getElementsByTagName('createdDateTime')[0].textContent;
      const formattedDateTime = format(new Date(dateTime), 'HH:mm');

      // Find the last non-zero price
      const lastValidPoint = xmlPoints.slice().reverse().find(point => point.price !== 0);

      setLastDataInfo({
        time: formattedDateTime,
        price: lastValidPoint ? lastValidPoint.price : 0
      });
    };

    fetchData();
  }, []);

  return (
    <>
      <div className='main' style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
        <h2 className='charth2'><br />ENTSOE HUPX Árak</h2>
        <p className='charth2'>Utolsó adat: {lastDataInfo.time} <span className='phonedisp'>|</span><span className='phonedisp2'><br /></span> Ár: {lastDataInfo.price.toFixed(0)}Ft/kWh </p>
        <Chart options={chartOptions} series={chartSeries} type="line" height={600} />
      </div>
    </>
  );
};

export default Entsoe;

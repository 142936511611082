import React, { useRef, useEffect, useState } from 'react';
import tt from '@tomtom-international/web-sdk-maps';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Mapss = () => {
  const mapElement = useRef();
  const [imageUrls, setImageUrls] = useState([]);
  const [weatherData, setWeatherData] = useState([]);
  const apiKey = process.env.REACT_APP_TOMTOM_API_KEY;

  useEffect(() => {
    fetch('/IMGURL.txt')
      .then(response => response.text())
      .then(text => {
        const urls = text.split('\n').slice(0, 4);
        setImageUrls(urls);
      })
      .catch(error => console.log('Error fetching image URLs:', error));
  }, []);

  useEffect(() => {
    fetch('/WEATHERDATA.txt')
      .then(response => response.text())
      .then(text => {
        let data = text.split('\n');
        if (data.length > 1) {
          const daysOfWeek = ["Vasárnap", "Hétfőn", "Kedden", "Szerdán", "Csütörtökön", "Pénteken","Szombaton"];
          let secondLine = data[1];
          let count = 0;

          daysOfWeek.forEach(day => {
            const regex = new RegExp(`(${day})`, 'g');
            secondLine = secondLine.replace(regex, (match) => {
              count++;
              if (count === 2) {
                return `<br /><br /><br /><div class="leftgridtextp1">${match}</div>`;
              }
              return match;
            });
          });

          data[1] = secondLine;
        }
        setWeatherData(data);
      })
      .catch(error => console.log('Error fetching weather data:', error));
  }, []);

  useEffect(() => {
    if (imageUrls.length > 0) {
      const map = tt.map({
        key: apiKey,
        container: mapElement.current,
        stylesVisibility: {
          trafficIncidents: false,
          trafficFlow: false,
        },
        center: [20.284939955660378, 47.85246154434458],
        zoom: 8,
        scrollZoom: false,
      });

      const addMarkerWithImage = (lngLat, imageUrl) => {
        const popup = new tt.Popup({ offset: 35 }).setHTML(`<img src="${imageUrl}" alt="Marker Image" style="width: 500px;"/>`);
        const marker = new tt.Marker().setLngLat(lngLat).setPopup(popup).addTo(map);
        marker.getPopup().addTo(map);
      };

      const preventScrollAndAddMarker = (lngLat, imageUrl) => {
        const currentScrollPosition = window.scrollY; // Store current scroll position
        addMarkerWithImage(lngLat, imageUrl);
        window.scrollTo(0, currentScrollPosition); // Restore scroll position
      };

      if (imageUrls[0]) preventScrollAndAddMarker([19.935026079737174, 47.823831843026106], imageUrls[0]);
      if (imageUrls[1]) preventScrollAndAddMarker([20.527769174066833, 47.744133928093525], imageUrls[1]);
      if (imageUrls[2]) preventScrollAndAddMarker([19.94772902102339, 47.90306711821566], imageUrls[2]);
      if (imageUrls[3]) preventScrollAndAddMarker([20.539665347895053, 47.795264885727796], imageUrls[3]);

      return () => map.remove();
    }
  }, [apiKey, imageUrls]);

  return (
    <div className='main'>
      <div ref={mapElement} className='maps-div maps-main' />

      <div className='maps_grid'>
        <div className="maps_mainn">
          <div className="maps_grid_left_content ">
            {weatherData.length > 0 && (
              <>
                <div className='left_grid_text'>{weatherData[0]}</div>
                {weatherData.slice(1).map((line, index) => (
                  <p key={index} className='leftgridtextp' dangerouslySetInnerHTML={{ __html: line }}></p>
                ))}
              </>
            )}
            <p style={{color: "white"}}>Forrás: időkép</p>
          </div>
        </div>
        <div className='maps_grid_right_content'>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
          >
            <SwiperSlide>
              <img src={imageUrls[0]} alt="gyongyossolymosdelkelet" className='mapSliderImg' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={imageUrls[1]} alt="mezoszemere" className='mapSliderImg' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={imageUrls[2]} alt="gyongyossolymoseszakkelet" className='mapSliderImg' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={imageUrls[3]} alt="mezokovesd" className='mapSliderImg' />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Mapss;

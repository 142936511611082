import './App.css';
import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Banner from './components/Banner';
import Footer from './components/Footer';
import Chart from './components/Chart';
import Maps from './components/Maps';
import Entsoe from './components/Entsoe';
import ScrollToTop from './components/ScrollToTop';
import Loader from './components/Loader';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLoginSuccess = () => {
    setIsLoading(true);
    // Simulate data loading
    setTimeout(() => {
      setIsLoggedIn(true);
      localStorage.setItem('isLoggedIn', 'true');
      setIsLoading(false);
    }, 2000); // Simulate loading time
  };

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    if (loggedIn) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <>
      {!isLoggedIn ? (
        <Login onLoginSuccess={handleLoginSuccess} />
      ) : (
        <div className="App">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <ScrollToTop />
              <Navbar onLogout={handleLogout} />
              <div id="home">
                <Banner />
              </div>
              <div id="termeles">
                <Chart />
              </div>
              <div id="entsoe">
                <Entsoe />
              </div>
              <div id="terkep">
                <Maps />
              </div>
              <Footer />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default App;

import React from 'react';

const TodayDate = () => {
    const today = new Date();
    const dateString = today.toLocaleDateString("en-US", { 
        
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
    });

   
    return (
        <div>
            <p>{dateString}</p>
        </div>
    );
};

export default TodayDate;

import React, { useState } from 'react';
import { GiSolarPower } from "react-icons/gi";

function Login({ onLoginSuccess }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    const hardcodedUsername = process.env.REACT_APP_LOGIN_USERNAME;
    const hardcodedPassword = process.env.REACT_APP_LOGIN_PASSWORD;

    if (username === hardcodedUsername && password === hardcodedPassword) {
      localStorage.setItem('isLoggedIn', 'true');
      onLoginSuccess();
    } else {
      setError('Helytelen felhsználónév vagy jelszó.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-image-div">
      </div>
      <form onSubmit={handleSubmit} className="login-form">
        <div className="login-icon">
          <GiSolarPower size={70} />
        </div>
        <h2 className="charth2" style={{textAlign: "center", margin: "2em 0", fontSize: "1.7rem"}}>EnergyForce KFT <br /> PV Monitoring</h2>
        <input 
          type="text" 
          value={username} 
          onChange={e => setUsername(e.target.value)} 
          placeholder="Felhasználónév" 
        />
        <input 
          type="password" 
          value={password} 
          onChange={e => setPassword(e.target.value)} 
          placeholder="Jelszó" 
        />
        <button type="submit">Belépés</button>
        {error && <div className="error-message">{error}</div>}
      </form>
    </div>
  );
}

export default Login;
